import { styled } from "@mui/material/styles";

export default styled("div")(({ theme, ownerState }) => {
  const { palette, functions, borders } = theme;
  const { error, success, disabled } = ownerState;

  const { inputColors, grey, white } = palette;
  const { pxToRem } = functions;
  const { borderRadius, borderWidth } = borders;

  // border color value
  let borderColorValue = inputColors.borderColor.main;

  if (error) {
    borderColorValue = inputColors.error;
  } else if (success) {
    borderColorValue = inputColors.success;
  }

  return {
    pointerEvents: disabled ? "none" : "auto",
    color: `${white.main} !important`,
    backgroundColor: disabled ? grey[600] : inputColors.backgroundColor,
    borderRadius: borderRadius.lg,
    border: `0.5px solid ${grey[600]}`,

    "& .MuiIcon-root": {
      color: grey[500],
    },

    "& .MuiInputBase-input": {
      color: white.main,
      height: "100%",
      paddingX: pxToRem(20),
      backgroundColor: disabled ? grey[600] : inputColors.backgroundColor,
    },

    "& .MuiInputBase-root": {
      border: `unset`,
      borderRadius: borderRadius.lg,
      // borderTopRightRadius: "0px",
      // borderBottomRightRadius: "0px",
      backgroundColor: `${disabled ? grey[600] : inputColors.backgroundColor} !important`,
      "& ::placeholder": {
        color: `${white.main} !important`,
      },
    },

    display: "flex",
    alignItems: "center",
    // borderColor: borderColorValue,
  };
});
