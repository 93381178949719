import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for VuiInput
import VuiInputRoot from "./components/VuiInputRoot";
import VuiInputWithIconRoot from "./components/VuiInputWithIconRoot";
import VuiInputIconBoxRoot from "./components/VuiInputIconBoxRoot";
import VuiInputIconRoot from "./components/VuiInputIconRoot";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard React contexts
import { useVisionUIController } from "context";

// Icon Cancel
import { IoCloseOutline } from "react-icons/io5";
import colors from "assets/theme/base/colors";

const VuiInputTextButton = forwardRef(
  ({ size, icon, cancelInput, onClickOk, error, success, disabled, ...rest }, ref) => {
    let template;
    const [controller] = useVisionUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    template = (
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <VuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}> */}
        {/* <VuiInputIconBoxRoot ownerState={{ size }} /> */}
        <VuiInputRoot {...rest} ownerState={{ size, error, success, disabled }} />
        {/* </VuiInputWithIconRoot> */}
        <VuiButton
          onClick={() => onClickOk()}
          variant="text"
          style={{
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            // marginLeft: 0,
            // marginRight: 2,
            backgroundColor: colors.success.main,
            // height: 28,
            marginLeft: 5,
            borderRadius: 5,
          }}
        >
          {/* <IoCloseOutline fontSize="small" /> */}
          Enviar
        </VuiButton>
      </div>
    );
    // template = <VuiInputRoot {...rest} ownerState={{ size, error, success, disabled }} />;

    return template;
  }
);

// Setting default values for the props of VuiInput
VuiInputTextButton.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  cancelInput: false,
  onClickCancelInput: null,
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the VuiInput
VuiInputTextButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  onClickCancelInput: PropTypes.func,
  cancelInput: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default VuiInputTextButton;
