import { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";

import Grid from "@mui/material/Grid";

import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInputText";
import VuiInputMask from "components/VuiInputMask";
import VuiRadio from "components/VuiRadio";
import VuiButtonCustom from "components/VuiButtonCustom";
import { ViuCircularProgress } from "components/VuiCircularWithValueLabel";

import breakpoints from "assets/theme/base/breakpoints";

import api, { backEndUrl, frontEndUrl, backImgUrl } from "services/api";
import { validateEmail } from "helpers/utilities";

import { Container, Content } from "./styles";

function VuiRow() {
  return <div style={{ height: 15 }}></div>;
}

const ForGotUserPassWord = ({ onClose }) => {
  const [typeSelectSend, setTypeSelectSend] = useState({
    email: false,
    whatsapp: false,
  });

  const [states, setStates] = useState({
    email: "",
    phone: "",
  });

  const [isSendMessage, setIsSetMessage] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);
  const [isDisabledButtonSent, setIsDisabledButtonSent] = useState(true);
  const isMobileView = window.innerWidth < breakpoints.values.lg;

  async function onSend() {
    // try {
    let arrHostTarget = {};

    if (typeSelectSend.email) {
      arrHostTarget = { type: "email", account: states.email };
    }
    if (typeSelectSend.whatsapp) {
      arrHostTarget = { type: "whatsapp", account: states.phone };
    }
    arrHostTarget.urlBackEnd = backEndUrl;
    arrHostTarget.urlBackImg = backImgUrl;
    arrHostTarget.urlFrontEnd = frontEndUrl;
    arrHostTarget.urlIncRedirect = `${frontEndUrl}/reset`;

    const result = await api.post("users/forgot_password", arrHostTarget, {});

    if (result) {
      if (!result.data.data.found) {
        setTimeout(() => {
          setIsMessageError(!result.data.data.found);
        }, 500);
        console.log(
          ">>> Sent result:found",
          !result.data.data.found,
          " isMessageError:",
          isMessageError
        );
      }

      // console.log(">>> Sent result:", result);
    } else {
      console.log(">>> Sent result:", result.data);
    }
    // Seap#n0c@123
    // } catch (error) {
    //   console.log(">>> Error send:", error);
    // }
  }

  useEffect(() => {
    setIsDisabledButtonSent(true);

    if (typeSelectSend.email && validateEmail(states.email) && states.email.length > 0) {
      setIsDisabledButtonSent(false);
    }

    if (typeSelectSend.whatsapp && states.phone.replace(/\ |\_|\(|\)|\-/g, "").length === 11) {
      setIsDisabledButtonSent(false);
    }
  }, [states]);

  if (isMessageError) {
    <Container>
      <Content>
        {isMobileView ? (
          <VuiBox p={3} mt={1} mb={1}>
            <VuiBox mb={2}>
              <VuiLabel
                title="Sua solicitação foi enviada!"
                sizeTitle={22}
                fontWeightTitle={"bold"}
              />
              <VuiRow />
              <VuiRow />
              <VuiLabel
                title={`Mas não encontramos o ${
                  typeSelectSend.email ? `e-mail:` : `whatsapp:`
                } cadastrado`}
                sizeTitle={16}
              />
              <VuiRow />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <VuiLabel
                  title={`${typeSelectSend.email ? `${states.email}"` : `${states.phone}`}`}
                  sizeTitle={18}
                  fontWeightTitle={"bold"}
                />
              </div>
              <VuiRow />
              <VuiRow />
            </VuiBox>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: isMobileView ? "center" : "right",
                paddingTop: 10,
              }}
            >
              <div style={{ width: "100%", paddingRight: 5 }}>
                <VuiButtonCustom
                  title={"Fechar"}
                  color="dark"
                  fontWeight="bold"
                  onClick={onClose}
                  style={{ width: 300 }}
                />
              </div>
            </div>
          </VuiBox>
        ) : (
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Sua solicitação foi enviada!" sizeTitle={22} />
              <VuiRow />
              <VuiRow />
              <VuiLabel
                title={`Verifique a notificação enviada no seu ${
                  typeSelectSend.email ? `e-mail:` : `whatsapp:`
                }`}
                sizeTitle={16}
              />
              <VuiRow />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <VuiLabel
                  title={`${typeSelectSend.email ? `${states.email}"` : `${states.phone}`}`}
                  sizeTitle={18}
                  fontWeightTitle={"bold"}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={12} xl={12}>
              <VuiButtonCustom title="Fechar" color="dark" onClick={onClose} />
            </Grid>
          </Grid>
        )}
      </Content>
    </Container>;
  }

  if (isMessageSent) {
    return (
      <Container>
        <Content>
          {isMobileView ? (
            <VuiBox p={3} mt={1} mb={1}>
              <VuiBox mb={2}>
                <VuiLabel
                  title="Sua solicitação foi enviada!"
                  sizeTitle={22}
                  fontWeightTitle={"bold"}
                />
                <VuiRow />
                <VuiRow />
                <VuiLabel
                  title={`Verifique a notificação enviada no seu ${
                    typeSelectSend.email ? `e-mail:` : `whatsapp:`
                  }`}
                  sizeTitle={16}
                />
                <VuiRow />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <VuiLabel
                    title={`${typeSelectSend.email ? `${states.email}"` : `${states.phone}`}`}
                    sizeTitle={18}
                    fontWeightTitle={"bold"}
                  />
                </div>
                <VuiRow />
                <VuiRow />
              </VuiBox>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: isMobileView ? "center" : "right",
                  paddingTop: 10,
                }}
              >
                <div style={{ width: "100%", paddingRight: 5 }}>
                  <VuiButtonCustom
                    title={"Fechar"}
                    color="dark"
                    fontWeight="bold"
                    onClick={onClose}
                    style={{ width: 300 }}
                  />
                </div>
              </div>
            </VuiBox>
          ) : (
            <Grid container spacing={3} p={3}>
              <Grid item xs={12} lg={12} xl={12}>
                <VuiLabel title="Sua solicitação foi enviada!" sizeTitle={22} />
                <VuiRow />
                <VuiRow />
                <VuiLabel
                  title={`Verifique a notificação enviada no seu ${
                    typeSelectSend.email ? `e-mail:` : `whatsapp:`
                  }`}
                  sizeTitle={16}
                />
                <VuiRow />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <VuiLabel
                    title={`${typeSelectSend.email ? `${states.email}"` : `${states.phone}`}`}
                    sizeTitle={18}
                    fontWeightTitle={"bold"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={12} xl={12}>
                <VuiButtonCustom title="Fechar" color="dark" onClick={onClose} />
              </Grid>
            </Grid>
          )}
        </Content>
      </Container>
    );
  }

  if (isSendMessage) {
    return (
      <Container>
        <Content>
          <Grid item xs={12} lg={12} xl={12} ml={5} mr={5}>
            <ViuCircularProgress
              timeOut={100}
              height={280}
              label={[{ min: 1, max: 100, value: 1, msg: "Aguarde... Enviando sua Solicitação" }]}
              onExec={() => {
                setIsSetMessage(false);
              }}
              onProgress={async (n) => {
                if (n === 45) {
                  console.log(">>> Aqui a mensagem é enviada para o servidor.");
                  await onSend();
                }
                if (n === 90) {
                  console.log(">>> isMessageError:90:", isMessageError);
                }
                if (n === 99) {
                  console.log(">>> isMessageError:99:", isMessageError);
                  if (isMessageError) {
                    setIsMessageSent(true);
                  }
                }
              }}
            />
          </Grid>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <VuiBox p={3} mt={1} mb={1}>
          <VuiBox mb={2}>
            <VuiLabel
              title="Esqueceu seu acesso ou sua senha!"
              sizeTitle={22}
              fontWeightTitle={"bold"}
            />
            <VuiRow />
            <VuiRow />
            {isMobileView ? (
              <VuiLabel
                title="Para saber quais suas credenciais de acesso ou redefinir sua senha, informe o e-mail ou número de celular cadastrado."
                sizeTitle={14}
              />
            ) : (
              <>
                <VuiLabel
                  title="Para saber quais suas credenciais de acesso ou redefinir"
                  sizeTitle={14}
                />
                <VuiLabel
                  title="sua senha, informe o e-mail ou número de celular cadastrado."
                  sizeTitle={14}
                />
              </>
            )}
          </VuiBox>

          {/*  */}
          <VuiBox mb={2}>
            <VuiRadio
              name="add"
              label="Enviar via E-mail"
              checked={typeSelectSend.email}
              onClick={() => {
                setTypeSelectSend((oldState) => ({
                  email: true,
                  whatsapp: false,
                }));
              }}
            />
            <VuiRadio
              name="transfer"
              label="Enviar via Whatsapp"
              checked={typeSelectSend.whatsapp}
              onClick={() => {
                setTypeSelectSend((oldState) => ({
                  whatsapp: true,
                  email: false,
                }));
              }}
            />
          </VuiBox>

          {typeSelectSend.whatsapp && (
            <VuiBox mb={2}>
              <VuiLabel title="Número do Celular Whatsapp" />
              <VuiInputMask
                name="phone"
                placeholder="Informe o número do celular"
                mask="phone"
                value={states?.phone}
                onChange={(e) => {
                  const value = e.target.value;
                  setStates({ ...states, phone: value });
                }}
              />
            </VuiBox>
          )}

          {typeSelectSend.email && (
            <VuiBox mb={2}>
              <VuiLabel title="E-mail" />
              <VuiInput
                name="email"
                placeholder="Informe um e-mail"
                value={states?.email}
                onChange={(e) => {
                  const value = e.target.value;
                  setStates({ ...states, email: value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) FocusNextField("phone");
                }}
              />
            </VuiBox>
          )}

          {!typeSelectSend.email && !typeSelectSend.whatsapp && (
            <VuiBox mb={2}>
              <VuiLabel title="E-mail ou Whatsapp" />
              <VuiInput name="email" placeholder="Selecione E-MAIL ou WHATSAPP" disabled />
            </VuiBox>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: isMobileView ? "center" : "right",
              paddingTop: 10,
            }}
          >
            <div style={{ width: "50%", paddingRight: 5 }}>
              <VuiButtonCustom
                title={"Fechar"}
                color="dark"
                fontWeight="bold"
                onClick={onClose}
                style={{ width: 300 }}
              />
            </div>
            <div style={{ width: "50%", paddingRight: 5 }}>
              <VuiButtonCustom
                title="Salvar"
                color="success"
                fontWeight="bold"
                onClick={() => {
                  setIsSetMessage(true);
                }}
                disabled={isDisabledButtonSent}
              />
            </div>
          </div>
        </VuiBox>
      </Content>
    </Container>
  );
};

export default ForGotUserPassWord;
