import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiOpenLink from "components/VuiOpenLink";

import logo3w from "assets/images/logos/logo-www.png";

const WelcomeMark = () => {
  return (
    <Card
      sx={() => ({
        height: "100%",
        py: "32px",
        // // backgroundImage: `url(${gif})`,
        // backgroundSize: "cover",
        // backgroundPosition: "50%",
        backgroundColor: "green",
      })}
    >
      <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <VuiBox>
          <VuiTypography color="white" variant="h3" fontWeight="regular" mb="12px">
            Seja bem vindo ao Comunica,
          </VuiTypography>
          <VuiTypography color="white" variant="h6" fontWeight="bold" mb="18px">
            Prezados colaboradores,
            {/* <br /> */}
            {/* muito bom te ver novamente... */}
          </VuiTypography>
          <VuiTypography color="white" variant="h6" fontWeight="regular" mb="auto">
            Reunimos aqui informações de todos os serviços disponível que você colaborador(a)
            precisa!
          </VuiTypography>
          <VuiTypography color="white" variant="h6" fontWeight="bold" mb="auto">
            <br />
            Informativo:
          </VuiTypography>
          <VuiTypography color="success" variant="h6" fontWeight="regular" mb="auto">
            <br />Fique por dentro das novidades da SRT - Supervisão de recursos Tecnologicos. Iremos disponibilizar um canal especialmente para você colaborador.
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="auto">
            <br />
            Acesso rápido:
          </VuiTypography>
          <VuiTypography color="text" variant="h6" mb="auto">
            <br />
            <VuiOpenLink
              title={"Chamado Técnico"}
              image={logo3w}
              url={"http://helpdesk.seap.ma.br/"}
            />
            <VuiOpenLink
              title={"Ramais Internos"}
              image={logo3w}
              url="voips"
              target=""
              icon="none"
            />
            {/* <VuiOpenLink title={"Mapa dos links"} image={logo3w} url="maps" target="" icon="none" /> */}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
