import styled from "styled-components";

import colors from "assets/theme/base/colors";
import breakpoints from "assets/theme/base/breakpoints";

const isMobileView = window.innerWidth < breakpoints.values.lg;

const useResolveColorTheme = ({ light, dark }) => {
  return dark;
};

export const Container = styled.article`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;

  width: ${(props) => (props.width ? `${props.width}%` : isMobileView ? "60%" : "10%")};
  height: ${(props) => (props.height ? `${props.height}%` : isMobileView ? "15%" : "10%")};

  padding: ${(props) => props.p}rem;

  background: ${() =>
    useResolveColorTheme({ light: "rgba(255, 255, 255, 0.15)", dark: colors.grey[700] })};
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.18);
`;
