import config from "../.config.json";

function checkLocalDomain() {
  //
  // Value localDomin:
  //
  // intranet = localhost && monitor.seap.ma.br
  // internet = monitor.seap.ma.gov.br
  //
  const localDomain =
    window.location.host.split(":")[0] === "0.0.0.0"
      ? "localhost"
      : window.location.host.split(":")[0];

  const isIntraNet =
    localDomain === "localhost" ||
    localDomain === "monitor.seap.ma.br" ||
    localDomain === "dev.monitor.seap.ma.br" ||
    localDomain === "10.34.7.2" ||
    localDomain === "10.34.7.143" ||
    localDomain === "192.168.101.50";

  const ipLocalDomain =
    config.production && config.localDev === "home" ? "192.168.101.50" : "localhost";

  return { isIntraNet, localDomain, ipLocalDomain };
}

export default checkLocalDomain;
