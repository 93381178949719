import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiOpenLink from "components/VuiOpenLink";

import colors from "assets/theme/base/colors";

import checkLocalDomain from "helpers/checkLocalDomain";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import logoSiisp from "assets/images/logos/logo-siisp.png";
import logoSigef from "assets/images/logos/logo-sigef.png";
import logoVelti from "assets/images/logos/logo-velti.png";
import logo3w from "assets/images/logos/logo-www.png";
import logoExtra from "assets/images/logos/logo-extranet.png";
import logoProSel from "assets/images/logos/logo-prosel.png";


const lstCurls = [
  {
    title: "Chamados de Suporte - SRT",
    image: logo3w,
    url: "support",
    type: 1,
  },
  {
    title: "Sistema Eletronico de Informações - SEI",
    image: logo3w,
    url: "https://sei.ma.gov.br",
    type: 1,
  },
  {
    title: "Sistema de Inteligência, Informação e Seguração Prisional",
    image: logoSiisp,
    url: "https://siisp.ma.gov.br",
    type: 1,
  },
  {
    title: "Sistema de Ponto - VELTI",
    image: logoVelti,
    url: "http://velti.seap.ma.br",
    type: 0,
  },
  { title: "Sistemas SEAP", image: logoExtra, url: "https://sistemas.seap.ma.gov.br/", type: 1 },
  {
    title: "Sistema Integrado de Planejamento e Gestão Fiscal",
    image: logoSigef,
    url: "http://sigef.seplan.ma.gov.br",
    type: 0,
  },
  {
    title: "Sistema de Recursos Humanos - PeopleSoft",
    image: logo3w,
    url: "http://renovarh.segep.ma.gov.br",
    type: 1,
  },
  {
    title: "Sistema de Processo Seletivo - PROSEL",
    image: logoProSel,
    url: "https://prosel.seap.ma.gov.br",
    type: 1,
  },
];

const getRandomObject = (array) => {
  const randomObject = array[Math.floor(Math.random() * array.length)];
  return randomObject;
};

function SystemLinks() {
  // const lstRandomUrl = [];

  // useEffect(() => {
  //   for (let index = 0; index < lstCurls.length; index++) {
  //     const currentItem = lstCurls[index];
  //     const newItem = getRandomObject(lstCurls);
  //     const findItem = lstRandomUrl.filter((x) => x.title === currentItem.title).length === 0;

  //     console.log(">>> lstRandomUrl", index, findItem);

  //     lstRandomUrl.filter((x) => {
  //       console.log(x.title, " >>> ", currentItem.title, x.title === currentItem.title);
  //       return x.title === currentItem.title;
  //     });

  //     if (findItem) {
  //       lstRandomUrl.push(newItem);
  //     }
  //   }

  //   console.log(">>> result lstRandomUrl", lstRandomUrl);
  // }, false);

  //
  const isIntraNet = checkLocalDomain().isIntraNet;

  return (
    <Card
      sx={{
        height: "100%",
        py: "32px",
        backgroundColor: "green",
      }}
    >
      <VuiBox>
        <VuiTypography color="text" variant="h3" fontWeight="regular" mb="12px">
          Acesso Rápido aos Sistemas SEAP
        </VuiTypography>
        <VuiBox sx={{ marginTop: "25px" }}>
          {lstCurls.map((item, index) => {
            if (item.type === 1) {
              return (
                <VuiOpenLink key={index} title={item.title} image={item.image} url={item.url} />
              );
            }
            if (item.type === 0 && isIntraNet) {
              return (
                <VuiOpenLink key={index} title={item.title} image={item.image} url={item.url} />
              );
            }
          })}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default SystemLinks;
