import { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { Toaster, toast } from "react-hot-toast";
import moment from "moment";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInputText";
import VuiButton from "components/VuiButton";
import VuiButtonCustom from "components/VuiButtonCustom";
import VuiSwitch from "components/VuiSwitch";
import VuiModal from "components/VuiModal";
import VuiLoading from "components/VuiLoading";

import Footer from "examples/Footer";

import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";

// Images
import bgSignIn from "assets/images/logos/logo-seap.png";

// Vision UI Dashboard React contexts
import { useVisionUIController, setUserLogged } from "context";

import api from "services/api";

// Local Components
import ForGotUserPassWord from "./components/forgotpasswd";

import { Container, Content } from "./styles";

const notify = (message) =>
  toast.error(message, {
    style: {
      fontSize: 14,
      borderRadius: "5px",
      background: "#333",
      color: "#fff",
    },
  });

function SignIn() {
  const [controller, dispatch] = useVisionUIController();
  const [loading, setLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [isOpenModalForGot, setIsOpenModalForGot] = useState(false);

  const history = useHistory();
  const pathRouter = history.location.search
    ? "/" + history.location.search.split("/")[1]
    : "/admin";

  const isMobileView = window.innerWidth < breakpoints.values.lg;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [false]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const login = async () => {
    const res = await api.post("users/login", { email: userName, passwd: passWord });
    const data = res.data;

    if (data.success) {
      const userInfo = data.data;
      userInfo.dateTimeOut = moment(userInfo.timeStampOut).format("DD/MM/YYYY HH:mm:ss");

      localStorage.clear();
      localStorage.setItem("timeStampOut", userInfo.timeStampOut);
      localStorage.setItem("token", userInfo.token);
      localStorage.setItem("logged", data.success);
      localStorage.setItem("user", JSON.stringify(userInfo));

      setUserLogged(dispatch, userInfo);

      history.push(pathRouter);
    } else {
      notify(data.message);
    }
  };

  if (loading) {
    return (
      <Container>
        <Content>
          <VuiLoading />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />

      {/*  Forgot password */}
      <VuiModal
        isOpen={isOpenModalForGot}
        onClose={() => {
          setIsOpenModalForGot(!isOpenModalForGot);
        }}
      >
        <ForGotUserPassWord onClose={() => setIsOpenModalForGot(!isOpenModalForGot)} />
      </VuiModal>

      <VuiBox mb={2}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={bgSignIn} alt="login" width={isMobileView ? 120 : 160} />
        </div>
      </VuiBox>

      <VuiBox>
        <VuiBox mb={2}>
          <VuiLabel title="Área Restrita" sizeTitle={20} fontWeightTitle={"bold"} />
          <VuiLabel title="Digite suas credenciais para entrar" sizeTitle={16} />
          <VuiLabel title="" sizeTitle={14} />
          <VuiLabel title="" sizeTitle={14} />

          {/*  */}
          <VuiLabel title="Usuário/e-mail" sizeTitle={14} />

          <VuiInput
            name="userName"
            value={userName}
            type="email"
            placeholder="Digite seu Usuário ou e-mail"
            fontWeight="bold"
            sx={({ typography: { size } }) => ({
              fontSize: size.sm,
            })}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13 && passWord.length > 0) {
                login();
              }
            }}
          />
        </VuiBox>

        <VuiBox mb={2}>
          <VuiLabel title="Senha" sizeTitle={14} />

          <VuiInput
            name="password"
            value={passWord}
            onChange={(e) => {
              setPassWord(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                login();
              }
            }}
            type="password"
            autocomplete="current-password"
            placeholder="Digite sua senha..."
            sx={({ typography: { size } }) => ({
              fontSize: size.sm,
            })}
          />
        </VuiBox>

        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="success" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiLabel title="&nbsp;&nbsp;&nbsp;&nbsp;Lembre-se de mim" sizeTitle={12} />
        </VuiBox>

        <VuiBox mt={2} mb={0}>
          <VuiButton
            color="success"
            fullWidth
            onClick={() => {
              login();
            }}
          >
            Entrar
          </VuiButton>
        </VuiBox>

        <VuiBox mt={2} mb={0}>
          <VuiButtonCustom
            title="Criar um novo usuário"
            color="transparent"
            icon="person_add"
            iconSize="small"
            iconSide="left"
            onClick={() => {
              // setIsOpenModal(!isOpenModal);
              history.push(`register?source=/login&redirect=${pathRouter}`);
            }}
          />
        </VuiBox>

        <VuiBox mt={0} mb={0}>
          <VuiButtonCustom
            title="Esqueci meu acesso ou minha senha"
            color="transparent"
            icon="shuffle"
            iconSize="medium"
            iconSide="right"
            onClick={() => setIsOpenModalForGot(!isOpenModalForGot)}
          />
        </VuiBox>
      </VuiBox>
      <VuiBox mt={4} mb={0} />
      <Footer />
    </Container>
  );
}

export default SignIn;
