import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Toaster, toast } from "react-hot-toast";

import { IoCheckmarkSharp } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInputText";
import VuiInputButton from "components/VuiInputTextButton";
import VuiInputMask from "components/VuiInputMask";
import VuiButtonCustom from "components/VuiButtonCustom";
import { ViuCircularProgress } from "components/VuiCircularWithValueLabel";

import Grid from "@mui/material/Grid";
import moment from "moment";

import useWindowDimensions from "hooks/useWindowDimensions";
import breakpoints from "assets/theme/base/breakpoints";

import colors from "assets/theme/base/colors";
import Footer from "examples/Footer";

import api, { backEndUrl, frontEndUrl, backImgUrl } from "services/api";
import { validateCPF, validateEmail } from "helpers/utilities";

// Images
import Loading from "examples/Icons/Loading";

import { Container, Content, TextArea } from "./styles";

const initStates = {
  registry: "",
  name: "",
  gender: "",
  initials: "",
  unit: "",
  department: "",
  IDdepartment: "",
  active: false,
  found: false,
  id_individual_register: "",
  email: "",
  emailCorp: "",
  phone: "",
  phoneCorp: "",
  bossName: "",
  bossRegistry: "",
  commentObservation: "",
};

function LoadingServiceOrder() {
  const { height, width } = useWindowDimensions();
  return (
    <VuiBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Loading />
    </VuiBox>
  );
}

const notify = (message) =>
  toast.error(message, {
    style: {
      fontSize: 14,
      borderRadius: "5px",
      background: "#333",
      color: "#fff",
    },
  });

function FocusNextField(fieldName) {
  // let inputs, index;

  // inputs = document.getElementsByTagName("input");
  // for (index = 0; index < inputs.length; ++index) {
  //   // deal with inputs[index] element.
  //   console.log(inputs[index].name);
  // }

  const nextfield = document.querySelector(`input[name=${fieldName}]`);
  nextfield.focus();

  return;
}

function VuiRow() {
  return <div style={{ height: 15 }}></div>;
}

function VuiContainer({ children }) {
  return <div style={{ marginTop: 0, width: "100%" }}>{children}</div>;
}

function VuiContainerItem({ children, isMobileView = false, xs = 12, lg = 12, xl = 12 }) {
  if (isMobileView) {
    return <div style={{ marginTop: 10, width: "100%" }}>{children}</div>;
  }
  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
}

function CheckPassword({ isDonneCheck }) {
  return (
    <>
      <div
        style={{
          textAlign: "left",
          fontSize: 11,
          color: "#fff",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        Sua senha, deverá conter no mínimo oito caracteres e deverá obedecer os requisitos:
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isDonneCheck.check1 ? (
            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
          ) : (
            <IoCheckmarkSharp size={18} />
          )}
          <p style={{ marginLeft: 5 }}>Primeira letras maiúsculas (A-Z)</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isDonneCheck.check2 ? (
            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
          ) : (
            <IoCheckmarkSharp size={18} />
          )}
          <p style={{ marginLeft: 5 }}>Letras minúsculas (a-z)</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isDonneCheck.check3 ? (
            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
          ) : (
            <IoCheckmarkSharp size={18} />
          )}
          <p style={{ marginLeft: 5 }}>Números (0-9)</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isDonneCheck.check4 ? (
            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
          ) : (
            <IoCheckmarkSharp size={18} />
          )}
          <p style={{ marginLeft: 5 }}>Caracteres especiais ($, %, @, ...)</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isDonneCheck.check5 ? (
            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
          ) : (
            <IoCheckmarkSharp size={18} />
          )}
          <p style={{ marginLeft: 5 }}>Tamanho da senha maior ou igual á 8 dígitos</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isDonneCheck.check6 ? (
            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
          ) : (
            <IoCheckmarkSharp size={18} />
          )}
          <p style={{ marginLeft: 5 }}>Senhas Iguais</p>
        </div>
      </div>
    </>
  );
}

const NewUserSupport = ({ onAction, lstDepartment, isMobileView }) => {
  const { height, width } = useWindowDimensions();
  const [states, setStates] = useState(initStates);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isFinishProcess, isSetFinishProcess] = useState(false);
  const [isSendButtonRegister, setIsSendButtonRegister]=useState(true)
  const [isDonneCheck, setIsDonneCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check6: false,
  });
  const [resultSave, setResultSave] = useState({});
  const [onClose, setOnClose] = useState(false);

  async function saveUser() {
    const arrSave = {
      ...states,
      password: password,
      login:
        states.name
          .split(" ")[0]
          .toLowerCase()
          .replace("ç", "c")
          .replace(/[aáàãâä]/g, "a")
          .replace(/[eéèêë]/g, "e")
          .replace(/[iíìîï]/g, "i")
          .replace(/[oóòõôö]/g, "o")
          .replace(/[uúùûü]/g, "u") +
        "." +
        states.name
          .split(" ")
          [states.name.split(" ").length - 1].toLowerCase()
          .replace("ç", "c")
          .replace(/[aáàãâä]/g, "a")
          .replace(/[eéèêë]/g, "e")
          .replace(/[iíìîï]/g, "i")
          .replace(/[oóòõôö]/g, "o")
          .replace(/[uúùûü]/g, "u"),
      id_group: "2",
      id_departament: states.IDdepartment,
      department: states.department.trim(),
      initials: states.initials.trim(),
    };

    // console.log(">>> saveUser.arrSave .....:", arrSave);
    // const token = localStorage.getItem("token");
    // if (token) {
    const res = await api.post(`users/register`, arrSave, {
      // headers: { "Content-Type": "application/json", authorization: token },
    });
    // console.log(">>> saveUser.res.data ....:", res.data.data);

    return { ...res.data.data };
    // }
  }

  useEffect(() => {
    const disabledButtonSave =
      states.email.length !== 0 &&
      states.id_individual_register.length !== 0 &&
      states.phone.length !== 0 &&
      (states.bossRegistry ? states.bossRegistry.length !== 0 : false) &&
      (states.bossName ? states.bossName.length !== 0 : false) &&
      password.length >= 8 &&
      passwordConfirm.length >= 8 &&
      password === passwordConfirm;
    setIsDisabledSave(!disabledButtonSave);
    // console.log(">>>", !disabledButtonSave, password, passwordConfirm);
    // console.log(">>>", states);
  }, [states, password, passwordConfirm]);

  useEffect(() => {
    setIsDonneCheck({
      check1: /[A-Z]/.test(password[0]),
      check2: /[a-z]/.test(password),
      check3: /[0-9]/.test(password),
      check4: /[!@#$%ˆ&*()]/.test(password),
      check5: password.length >= 8,
      check6: password === passwordConfirm && password.length > 0 && passwordConfirm.length > 0,
    });
  }, [password, passwordConfirm]);

  if (isFinishProcess) {
    return (
      <Container>
        <Content>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Parabéns!" sizeTitle={42} />
              <VuiLabel
                title="Suas credenciais foram criadas com sucesso, a partir deste momento você poderá usar seu email ou login para acessar o suporte."
                sizeTitle={28}
              />
              <VuiRow />
              <VuiRow />
              <VuiRow />
              <VuiLabel
                title="Nome: "
                sizeTitle={24}
                subtitle={resultSave.name}
                sizeSubTitle={24}
              />
              <VuiLabel
                title="E-mail: "
                sizeTitle={24}
                subtitle={resultSave.email}
                sizeSubTitle={24}
              />
              <VuiLabel
                title="login: "
                sizeTitle={24}
                subtitle={resultSave.login}
                sizeSubTitle={24}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={10}>
              <VuiRow />
              <VuiRow />
            </Grid>

            <Grid item xs={12} lg={6} xl={10}></Grid>
            <Grid item xs={12} lg={6} xl={2}>
              <VuiButtonCustom
                title="Fechar"
                color="dark"
                onClick={() => {
                  onAction(1);
                }}
              />
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  }

  if (loadingSave) {
    return (
      <Container>
        <Content>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12} lg={12} xl={12}>
              <ViuCircularProgress
                label={[
                  { min: 1, max: 79, value: 1, msg: "Aguarde... Salvando informações" },
                  // { min: 41, max: 79, value: 55, msg: "Aguarde... Criando assinatura digital" },
                  { min: 80, max: 100, value: 100, msg: "Aguarde... Finalizando o processo" },
                ]}
                onExec={() => {
                  setLoadingSave(false);
                }}
                onProgress={async (n) => {
                  {
                    if (n === 15) {
                      console.log(">>> Salvar informações aqui", n);
                      const result = await saveUser();
                      setResultSave(result);
                    }
                    // if (n === 55) {
                    //   console.log(">>> Criar assinatura digital", n);
                    //   // const result = await createPgp(resultSave);
                    // }
                    if (n === 85) {
                      console.log(">>> Finalizando processo", n);
                    }
                    if (n >= 99) {
                      isSetFinishProcess(true);
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  }

  // View WEB
  return (
    <div
      style={{
        margin: isMobileView ? 15 : 0,
        borderRadius: 15,
        padding: isMobileView ? 10 : 0,
        background: colors.grey[700],
      }}
    >
      <Grid
        container
        spacing={2}
        p={isMobileView ? 2 : 0}
        sx={{
          marginLeft: isMobileView ? -1 : "",
        }}
      >
        {/* Line 1 */}
        <VuiContainerItem isMobileView={isMobileView} xl={12}>
          <VuiLabel title="Novo Usuário" sizeTitle={32} />
          <VuiRow />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={3}>
          <VuiLabel title="Matrícula" />
          <VuiInputButton
            placeholder={`Informe a matricula`}
            value={states.registry}
            type="tel"
            onChange={async (e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              setStates({ ...states, registry: value });
            }}
            onClickOk={async () => {
              // const value = e.target.value;
              // setStates({ ...states, registry: value });
              const value = states.registry;

              if (value.length > 3) {
                let arrUserFound = { ...initStates };

                const checkUserIdVelti = await api.get(`users/s?registration_velti=${value}`);

                if (checkUserIdVelti.data.data.user) {
                  notify(
                    `Usuário "${dataVelti.name.split(" ")[0]} ${
                      dataVelti.name.split(" ")[dataVelti.name.split(" ").length - 1]
                    }" já cadastrad${dataVelti.gender.substr(0, 1) === "M" ? "o" : "a"}...`
                  );
                  return null;
                }

                const res = await api.get(`velti/s?register=${value}`);
                const dataVelti = res.data;

                console.log(">>> velti/s?register:dataVelti:", dataVelti);

                if (!dataVelti.found) {
                  notify(`Matricula do colaborador VELTI não cadastrado...`);
                  return null;
                }

                if (dataVelti.found) {
                  const idIndividualRegister = dataVelti.id_individual_register;

                  console.log(">>> idIndividualRegister:", idIndividualRegister);

                  const res = await api.get(
                    `users/s?id_individual_register=${idIndividualRegister}`
                  );

                  const dataUser = res.data?.data || {};

                  console.log(">>> res.data:", res.data.data);
                  console.log(">>> dataUser:", dataUser);
                  // console.log(">>> dataVelti:", dataVelti);

                  const found = dataUser?.found || false;

                  if (found) {
                    console.log(">>> dataUser: Encontrado");
                    notify(
                      `Usuário "${dataVelti.name.split(" ")[0]} ${
                        dataVelti.name.split(" ")[dataVelti.name.split(" ").length - 1]
                      }" já cadastrad${dataVelti.gender.substr(0, 1) === "M" ? "o" : "a"}...`
                    );
                  } else {
                    console.log(">>>");
                    console.log(">>> dataUser: Não encontrado");
                    // console.log(">>> dataVelti:", dataVelti);
                    // console.log(">>> arrUserFound:", { ...dataVelti });
                    // console.log(">>> lstDepartment:", { ...lstDepartment });
                    arrUserFound = { ...dataVelti };

                    const lstFilterDepartment = lstDepartment.filter(
                      (x) => x.acronym === arrUserFound.initials.trim()
                    );

                    console.log(">>> lstFilterDepartment:", lstFilterDepartment);

                    const res = await api.get(
                      `velti/bossdepartments?id=${lstFilterDepartment[0].value}`
                    );

                    arrUserFound.department = lstFilterDepartment[0].label;
                    arrUserFound.IDdepartment = lstFilterDepartment[0].value;

                    if (value !== res.data.id) {
                      arrUserFound.bossRegistry = res.data.id;
                      arrUserFound.bossName = res.data.name;
                    }

                    console.log(">>> registry .......:", states.registry);
                    console.log(">>> value ..........:", value);
                    console.log(">>> res.data.id ....:", res.data.id);
                    console.log(">>> arrUserFound ...:", arrUserFound);
                    console.log(">>> res.............:", res.data);

                    // velti/bossdepartments?id=259
                    // setListDepartment(lstFilterDepartment);

                    if (arrUserFound.id_individual_register === "") {
                      FocusNextField("id_individual_register");
                    } else {
                      if (arrUserFound.email === "") {
                        FocusNextField("email");
                      } else {
                        if (arrUserFound.phone === "") {
                          FocusNextField("phone");
                        }
                      }
                    }
                  }
                }
                setStates({ ...states, ...arrUserFound, registry: value });
                setIsSendButtonRegister(false)

              } else if (value.length === 0 || value === 0) {
                setStates(initStates);
              }
            }}
          />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={3}>
          <VuiLabel title="CPF" />
          <VuiInputMask
            placeholder="Informe seu CPF"
            mask="cpf"
            name="id_individual_register"
            value={states?.id_individual_register}
            disabled={isSendButtonRegister}
            onChange={async (e) => {
              const value = e.target.value;
              const lengthValue = value.replace(/\_|\.|\-/g, "").length;
              setStates({ ...states, id_individual_register: value });
              if (lengthValue === 11) {
                if (validateCPF(value.replace(/\_|\.|\-/g, ""))) {
                  const res = await api.get(`users/s?id_individual_register=${value}`);
                  const dataUser = res.data?.data || {};
                  const found = dataUser?.found || false;
                  if (found) {
                    console.log(">>> found ..........:", found);
                    notify("CPF já cadastrado em outro usuário!");
                  } else {
                    FocusNextField("userName");
                  }
                } else {
                  notify(`Número de CPF "${value}" inválido`);
                }
              }
            }}
          />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={6}>
          <VuiLabel title="Nome" />
          <VuiInput
            name="userName"
            disabled={isUserNotFound}
            placeholder={`Informe o nome completo`}
            value={states.name}
            onChange={(e) => {
              if (!states.found) {
                const value = e.target.value;
                setStates({ ...states, name: value });
              }
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("email");
            // }}
          />
        </VuiContainerItem>

        {/*  */}
        {/* Line 2 */}
        <VuiContainerItem isMobileView={isMobileView} xl={3}>
          <VuiLabel title="E-mail Pessoal" />
          <VuiInput
            name="email"
            placeholder="Informe um e-mail"
            value={states?.email}
            disabled={isSendButtonRegister}
            onChange={(e) => {
              const value = e.target.value;
              setStates({ ...states, email: value });
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("phone");
            // }}
          />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={3}>
          <VuiLabel title="E-mail Funcional" />
          <VuiInput
            name="emailCorp"
            placeholder="Informe um e-mail"
            value={states?.emailCorp}
            disabled={isSendButtonRegister}
            onChange={(e) => {
              const value = e.target.value;
              setStates({ ...states, emailCorp: value });
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("phone");
            // }}
          />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={3}>
          <VuiLabel title="Número do Celular Pessoal" />
          <VuiInputMask
            name="phone"
            placeholder="Informe o número do celular"
            mask="phone"
            value={states?.phone}
            disabled={isSendButtonRegister}
            onChange={(e) => {
              const value = e.target.value;
              setStates({ ...states, phone: value });
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("selectUnit");
            // }}
          />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={3}>
          <VuiLabel title="Número do Celular Funcional" />
          <VuiInputMask
            name="phoneCorp"
            placeholder="Informe o número do celular"
            mask="phone"
            value={states?.phoneCorp}
            disabled={isSendButtonRegister}
            onChange={(e) => {
              const value = e.target.value;
              setStates({ ...states, phoneCorp: value });
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("selectUnit");
            // }}
          />
        </VuiContainerItem>

        {/* Line 3 */}

        {/* {states.found && states.department.length > 0 && ( */}
        <VuiContainerItem isMobileView={isMobileView} l={12}>
          <VuiLabel title="Departamento" />
          <VuiInput value={states?.department} disabled />
        </VuiContainerItem>

        {/* )} */}

        {/* Line 4 */}
        <VuiContainerItem isMobileView={isMobileView} xl={4}>
          <VuiLabel title="Chefe do Departamento (matricula)" />
          <VuiInputButton
            placeholder={`Informe a matricula campo obrigatório`}
            value={states.bossRegistry}
            onChange={async (e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");
              setStates({ ...states, bossRegistry: value });
            }}
            onClickOk={async () => {
              const value = states.bossRegistry;
              setStates({ ...states, bossRegistry: value });
              if (value.length > 3) {
                let arrUserFound = { bossName: "" };
                const res = await api.get(`velti/s?register=${value}`);

                console.log(">>> res.data", res.data);

                if (res.data.found) {
                  arrUserFound = { bossName: res.data.name };
                  FocusNextField("password");
                } else {
                  notify(`Matricula do colaborador VELTI não cadastrado...`);
                  return null;
                }
                setStates({ ...states, ...arrUserFound, bossRegistry: value });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) FocusNextField("password");
            }}
          />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={8}>
          <VuiLabel title="Nome" />
          <VuiInput placeholder={`Informe o nome completo`} value={states.bossName} disabled />
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} l={12}>
          <VuiLabel title="Observação:" />
          <TextArea
            // disabled={isDisabledText}
            rows={4}
            multiline
            name="commentObservation"
            value={states.commentObservation}
            onChange={(e) => {
              setStates({
                ...states,
                commentObservation: e.target.value,
              });
            }}
            placeholder={"Descreva aqui alguma observação para cadastro"}
          />
        </VuiContainerItem>

        {/* Line 5 */}
        <VuiContainerItem isMobileView={isMobileView} xl={6}>
          <VuiLabel title="Senha" />
          <VuiInput
            name="password"
            type="password"
            placeholder="Informe uma senha forte padrão"
            value={password}
            onChange={(e) => {
              const value = e.target.value;
              setPassword(value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) FocusNextField("password");
            }}
          />
          {!isMobileView && (
            <div
              style={{
                textAlign: "left",
                fontSize: 11,
                color: "#fff",
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Sua senha, deverá conter no mínimo oito caracteres e deverá obedecer os requisitos:
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isDonneCheck.check1 ? (
                  <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                ) : (
                  <IoCheckmarkSharp size={18} />
                )}
                <p style={{ marginLeft: 5 }}>Primeira letras maiúsculas (A-Z)</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isDonneCheck.check2 ? (
                  <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                ) : (
                  <IoCheckmarkSharp size={18} />
                )}
                <p style={{ marginLeft: 5 }}>Letras minúsculas (a-z)</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isDonneCheck.check3 ? (
                  <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                ) : (
                  <IoCheckmarkSharp size={18} />
                )}
                <p style={{ marginLeft: 5 }}>Números (0-9)</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isDonneCheck.check4 ? (
                  <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                ) : (
                  <IoCheckmarkSharp size={18} />
                )}
                <p style={{ marginLeft: 5 }}>Caracteres especiais ($, %, @, ...)</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isDonneCheck.check5 ? (
                  <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                ) : (
                  <IoCheckmarkSharp size={18} />
                )}
                <p style={{ marginLeft: 5 }}>Tamanho da senha maior ou igual á 8 dígitos</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {isDonneCheck.check6 ? (
                  <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                ) : (
                  <IoCheckmarkSharp size={18} />
                )}
                <p style={{ marginLeft: 5 }}>Senhas Iguais</p>
              </div>
            </div>
          )}
        </VuiContainerItem>

        <VuiContainerItem isMobileView={isMobileView} xl={6}>
          <VuiLabel title="Confirme a Senha" />
          <VuiInput
            name="passwordConfirm"
            type="password"
            placeholder="Confirme a senha informada"
            value={passwordConfirm}
            onChange={(e) => {
              const value = e.target.value;
              setPasswordConfirm(value);
            }}
          />
          {isMobileView && <CheckPassword isDonneCheck={isDonneCheck} />}
        </VuiContainerItem>

        {/*  */}
        <VuiContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: isMobileView ? "center" : "right",
              paddingTop: 10,
            }}
          >
            <div style={{ width: isMobileView ? "50%" : "16%", paddingRight: 5 }}>
              <VuiButtonCustom
                title={"Fechar"}
                color="dark"
                fontWeight="bold"
                onClick={() => {
                  onAction(0);
                }}
                style={{ width: 300 }}
              />
            </div>
            <div style={{ width: isMobileView ? "50%" : "16%", paddingRight: 5 }}>
              <VuiButtonCustom
                title="Salvar"
                color="success"
                fontWeight="bold"
                onClick={() => {
                  setLoadingSave(true);
                  setIsDisabledSave(true);
                }}
                disabled={isDisabledSave}
              />
            </div>
          </div>
        </VuiContainer>
      </Grid>
    </div>
  );
};

function Register() {
  const history = useHistory();

  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [isOnClose, setIsOnClose] = useState({ close: false, module: 0 });
  const [lstDepartment, setLstDepartment] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);

  const pathRouter = history.location.search
    ? history.location.search.split("?")[1].split("&")
    : ["/", "/admin"];
  const pathRouterSource = pathRouter[0].split("/")[1];
  const pathRouterRedirect = pathRouter[1].split("/")[1];

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    if (isOnClose.close) {
      history.push(`/login?redirect=/${pathRouterRedirect}`);
    }
  }, [isOnClose]);

  useEffect(() => {
    setLoading(true);

    console.log(">>> pathRouterSource .....:", pathRouterSource);
    console.log(">>> pathRouterRedirect ...:", pathRouterRedirect);
    async function loadDepartment() {
      try {
        const res = await api.get(`velti/departments`);
        if (res.data.length > 0) {
          setLstDepartment(
            res.data.map((i) => {
              return {
                value: i.id,
                label: i.acronym + " - " + i.department,
                acronym: i.acronym,
                unit: i.unit,
              };
            })
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(">>> Error:loadDepartment:", error);
      }
    }

    setTimeout(() => {
      loadDepartment();
      setLoading(false);
    }, 1500);
  }, [false]);

  // Mobile Viewer
  if (isMobileView) {
    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <>
          {loading ? (
            <Container>
              <LoadingServiceOrder />
              <Footer />
            </Container>
          ) : (
            <>
              <NewUserSupport
                onAction={(result) => {
                  console.log(">>> result", result);
                  setIsOnClose({ ...isOnClose, close: !isOnClose.close, module: result });
                }}
                lstDepartment={lstDepartment}
                isMobileView={isMobileView}
              />
              <Footer />
            </>
          )}
        </>
      </>
    );
  }

  // Web Viewer
  return (
    <Container>
      <Toaster position="top-right" reverseOrder={false} />
      <Content width={isMobileView ? 90 : 60} p={isMobileView ? 0.5 : 2.25}>
        {loading ? (
          <LoadingServiceOrder />
        ) : (
          <NewUserSupport
            onAction={(result) => {
              console.log(">>> result", result);
              setIsOnClose({ ...isOnClose, close: !isOnClose.close, module: result });
            }}
            lstDepartment={lstDepartment}
            isMobileView={isMobileView}
          />
        )}
      </Content>
      <Footer />
    </Container>
  );
}

export default Register;
