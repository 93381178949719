import axios from "axios";
import checkLocalDomain from "helpers/checkLocalDomain";
import config from "../.config.json";
import appVersion from "../../package.json";

let backEndServer = `http://${checkLocalDomain().localDomain}:3333/`;
let backImgServer = `http://${checkLocalDomain().localDomain}:3333/images/`;
let frontEndServer = `http://${checkLocalDomain().localDomain}:3000/`;

if (config.localDev === "home" && config.production) {
  backEndServer = `http://${checkLocalDomain().ipLocalDomain}:3333/`;
  backImgServer = `http://${checkLocalDomain().ipLocalDomain}:3333/images/`;
  frontEndServer = `http://${checkLocalDomain().ipLocalDomain}:3000/`;
}

if (config.localDev === "seap" && config.production) {
  frontEndServer = checkLocalDomain().isIntraNet
    ? `http://monitor.seap.ma.br/`
    : "https://monitor.seap.ma.gov.br/";
  backEndServer = "https://api.monitor.seap.ma.gov.br/";
  backImgServer = "https://api.monitor.seap.ma.gov.br/images/";
}

// backEndServer = checkLocalDomain().isIntraNet
//   ? "https://api.monitor.seap.ma.br/"
//   : "https://api.monitor.seap.ma.gov.br/";

// backImgServer = checkLocalDomain().isIntraNet
//   ? "https://api.monitor.seap.ma.br/images/"
//   : "https://api.monitor.seap.ma.gov.br/images/";

const token = localStorage.getItem("token");

axios.defaults.headers = {
  "Content-Type": "application/json",
  authorization: token,
};

console.log(">>>>> API create connection", config.production ? "Production" : "Developer", "<<<<<");
console.log(
  `Todos os direitos @ 2023 - versão ${appVersion.version} - Developer by MAVO® - Licensed for SRT/SAAME/SEAP\n\n`
);

if (checkLocalDomain().isIntraNet) {
  console.log(">>>>> FrontEnd URL ..........:", frontEndServer);
  console.log(">>>>> BackEnd URL ...........:", `${backEndServer}api/v1/`);
  console.log(">>>>> BackEnd IMG ...........:", `${backImgServer}`);
  console.log(">>>>> Local DEV .............:", config.localDev.toUpperCase());

  console.log(">>>>> isIntraNet ............:", checkLocalDomain().isIntraNet);
  console.log(">>>>> ipLocalDomain .........:", checkLocalDomain().ipLocalDomain);
  console.log(">>>>> localDomain ...........:", checkLocalDomain().localDomain);
}

const api = axios.create({
  baseURL: `${backEndServer}api/v1/`,
});

export const backEndUrl = backEndServer;
export const backImgUrl = backImgServer;
export const frontEndUrl = frontEndServer;

export default api;
